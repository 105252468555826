<template>
    <div>
        <validation-observer ref="registerValidation">
            <div class="form-heading">
                <div class="form-heading-emoji-wrapper">
                    <img src="/emoji/confetti.png" alt="confetti-img">
                </div>
                <div class="form-title">Create an Account!</div>
            </div>

            <b-form @submit.prevent="register">
                <validated-form-input
                    label="Company Name"
                    input-id="company-name-input"
                    validation-name="company name"
                    placeholder="Enter the name of your company"
                    v-model="companyName"
                />
                <validated-form-input
                    label="E-mail"
                    input-id="email-input"
                    validation-name="e-mail"
                    placeholder="Enter your e-mail"
                    rules="required|email"
                    type="email"
                    v-model="userEmail"
                />
                <validated-form-input
                    label="Password"
                    input-id="password-input"
                    validation-name="password"
                    placeholder="Enter your password"
                    type="password"
                    v-model="password"
                />

                <b-button type="submit" class="w-100 mt-1 mt-md-2 submit-form-btn" :disabled="loading">
                    <template v-if="loading">
                        <b-spinner small class="mr-50"/>
                        <span class="align-middle">Loading</span>
                    </template>
                    <span v-else>Sign Up</span>
                </b-button>

                <p class="text-center mt-2"><span>Already have account? </span>
                    <router-link :to="{ name: 'auth.login' }"><span> Sign in</span></router-link>
                </p>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import {BForm, BButton, BSpinner} from "bootstrap-vue";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import AuthService from "@/services/AuthService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: 'AuthRegister',
    components: {
        BForm,
        BButton,
        BSpinner,
        ValidationObserver,
        ValidatedFormInput,
        AuthCardLeft
    },
    data() {
        return {
            loading: false,
            companyName: '',
            userEmail: '',
            password: '',
        }
    },
    methods: {
        resetForm() {
            this.companyName = '';
            this.userEmail = '';
            this.password = '';
            this.$refs.registerValidation.reset();
        },
        async register() {
            const isFormValid = await this.$refs.registerValidation.validate();

            if (!isFormValid || this.loading) return;

            try {
                this.loading = true;
                const referral = this.$route.query && this.$route.query.ref ? this.$route.query.ref : null;

                await AuthService.post.register(this.companyName, this.userEmail, this.password, referral);
                await this.$store.dispatch('auth/login', { email: this.userEmail, password: this.password });

                this.loading = false;
                this.resetForm();

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Account successfully created!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        hideClose: true
                    },
                });

                return this.$router.push({ name: 'auth.setup-payments' });
            } catch (err) {
                this.loading = false;

                if (+err.response.status === 422) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: err.response.data.message,
                            icon: 'XIcon',
                            variant: 'danger',
                            hideClose: true
                        },
                    });
                }
            }
        }
    }
}
</script>
